import "../scss/style.scss";

import "./libs/dynamic_adapt.js";
import './files/utils/inline-svg.js';
import './libs/inputmask.min.js';
//import './libs/wNumb.min.js';
import * as flsFunctions from "./files/utils/functions.js";
//flsFunctions.isWebp();
//flsFunctions.addTouchClass();
//flsFunctions.addLoadedClass();
//flsFunctions.menuOpen();
// flsFunctions.fullVHfix();
flsFunctions.spollers();
//flsFunctions.tabs();
flsFunctions.tabsAdaptive();
import * as flsForms from "./files/forms/forms.js";
flsForms.formFieldsInit();
flsForms.formSubmit(true);
//flsForms.formQuantity();
//flsForms.formViewpass();
//flsForms.formRating();
//import "./files/forms/select.js";
//import "./files/forms/nouislider.js";
import "./files/utils/popups.js";
//import "./files/utils/tippy.js";
import "./files/utils/sliders.js";
//import './files/scroll/simplebar.js';
import './files/scroll/lazyload.js';
import * as flsScroll from "./files/scroll/scroll.js";
// flsScroll.scrollWatcher(false);
// flsScroll.pageNavigation();
flsScroll.windowScroll();
import "./files/script.js";